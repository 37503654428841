import React from "react";

import Container from "components/Container";

import rectangleBackground from "assets/rectangle.svg";
import triangleBackground from "assets/triangle.svg";
import circleBackground from "assets/circle.svg";

import smartNudgeGif from "assets/smart-nudge.gif";
import productivityCompanionGif from "assets/productivity-companion.gif";
import timeBlockGif from "assets/time-block.gif";

const Feature = () => {
  return (
    <Container>
      <h2 className="font-header text-center tracking-tight font-bold text-purple-600 text-4xl lg:text-5xl">
        How it works
      </h2>
      <div className="text-center lg:text-left">
        <div className="flex flex-col py-12 lg:flex-row lg:justify-around">
          <img className="lg:h-128" src={smartNudgeGif} alt="smart nudge" />
          <div
            className="inline-block bg-no-repeat bg-bottom bg-16 bg-contain"
            style={{ backgroundImage: `url(${rectangleBackground})` }}
          >
            <p className="mt-4 text-base leading-tight text-yellow-500 font-bold text-2xl sm:mt-5 md:mt-8">
              Smart Nudges
            </p>
            <p className="mt-4 text-sm font-medium text-purple-800 sm:mt-5 md:text-base md:mt-8">
              We know when you’re unproductive - because you told us so.
              <br className="hidden md:block lg:block" /> So we’ll be sure to
              remind you that shouldn’t be watching your
              <br className="hidden md:block lg:block" /> favourite Netflix
              series. If you’d just like a break however, you
              <br className="hidden md:block lg:block" /> may set up a time
              block to take a break away from work.
            </p>
          </div>
        </div>
        <div className="flex flex-col py-12 lg:flex-row-reverse lg:justify-around">
          <img
            className="lg:h-128"
            src={productivityCompanionGif}
            alt="productivity companion"
          />
          <div
            className="inline-block bg-no-repeat bg-right-bottom bg-16 bg-contain"
            style={{ backgroundImage: `url(${triangleBackground})` }}
          >
            <p className="mt-4 text-base leading-tight text-yellow-500 font-bold text-2xl sm:mt-5 md:mt-8">
              Your Work-Life Companion
            </p>
            <p className="mt-4 text-sm font-medium text-purple-800 sm:mt-5 md:text-base md:mt-8">
              Review your day, witness your progress over time
              <br className="hidden md:block lg:block" /> and receive actionable
              insights on how you can
              <br className="hidden md:block lg:block" /> improve and build
              healthier habits. We’ll be here
              <br className="hidden md:block lg:block" /> to help along the
              journey towards building healthier
              <br className="hidden md:block lg:block" /> habits one step at a
              time.
            </p>
          </div>
        </div>
        <div className="flex flex-col py-12 lg:flex-row lg:justify-around">
          <img className="lg:h-128" src={timeBlockGif} alt="time block" />
          <div
            className="inline-block bg-no-repeat bg-left-bottom bg-16 bg-contain"
            style={{ backgroundImage: `url(${circleBackground})` }}
          >
            <p className="mt-4 text-base leading-tight text-yellow-500 font-bold text-2xl sm:mt-5 md:mt-8">
              Task Organiser
            </p>
            <p className="mt-4 text-sm font-medium text-purple-800 sm:mt-5 md:text-base md:mt-8">
              Sometimes the easiest way to take better control
              <br className="hidden md:block lg:block" /> of your time is to get
              organised. You can easily add
              <br className="hidden md:block lg:block" /> a string of To-Do’s,
              set up time blocks and record
              <br className="hidden md:block lg:block" /> the sites you need for
              them. That way, so you can
              <br className="hidden md:block lg:block" /> stay focused without
              annoying pop-ups when you’re
              <br className="hidden md:block lg:block" /> actually doing work.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Feature;
